/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  useGetDocumentQuery,
  useUpdateDocumentMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { toast } from 'react-toastify'
import HeaderWithBackArrow from 'layouts/MainLayout/Headers/HeaderWithBackArrow'
import PageContainer from 'layouts/PageContainer/PageContainer'
import MediaProcessorFallback from 'components/MediaProcessors/MediaProcessorFallback/MediaProcessorFallback'

/* Type imports ------------------------------------------------------------- */
import type { PdfViewerComponent } from '@syncfusion/ej2-react-pdfviewer'
import type { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor'

/* Lazy component imports --------------------------------------------------- */
const PdfProcessor = React.lazy(() => import(/* webpackPrefetch: true */ 'components/MediaProcessors/PdfProcessor/PdfProcessor' /* webpackChunkName: "pdf" */))
const ImageProcessor = React.lazy(() => import(/* webpackPrefetch: true */ 'components/MediaProcessors/ImageProcessor/ImageProcessor' /* webpackChunkName: "img" */))

/* Styled components -------------------------------------------------------- */
const StyledButton = styled(Button)`
  margin-left: auto;
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryAnnotatePageProps {}

const MediaLibraryAnnotatePage: React.FC<MediaLibraryAnnotatePageProps> = () => {
  const navigate = useNavigate()
  const { mediaId } = useParams<{mediaId: string}>()
  const [ source, setSource ] = useState<{url: string; type: string}>({ url: '', type: '' })
  const [ pdfRef, setPdfRef ] = useState<PdfViewerComponent | null>(null)
  const [ imgRef, setImgRef ] = useState<ImageEditorComponent | null>(null)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const {
    currentData: media,
    isFetching: isFetchingDocument,
  } = useGetDocumentQuery(mediaId || '')
  const [
    updateDocument,
    { isLoading: isUpdatingDocument },
  ] = useUpdateDocumentMutation()

  useEffect(() => {
    if (!media || isFetchingDocument) {
      return
    }

    const fileType = media.fileType.split('/')
    let type = ''

    if (fileType[0] === 'image') {
      type = 'img'
    }
    else if (fileType[1] === 'msword') {
      type = 'word'
    }
    else if (fileType[1] === 'pdf') {
      type = 'pdf'
    }

    setSource({ url: media.url || '', type })

    if (imgRef && media.url) {
      imgRef.open(media.url)
    }
  }, [ media, isFetchingDocument, imgRef ])

  const onSubmit = async (file: File) => {
    const response = await updateDocument({ id: mediaId || '', data: { Fichier: file, Nom: file.name }})
    if (isApiError(response)) {
      toast.error(`Une erreur est survenue lors de l'enregistrement ${source.type === 'pdf' ? 'du document' : "de l'image"}.`)
    } else {
      toast.success(source.type === 'pdf' ? 'Le document à bien été modifié.' : "L'image a bien été modifiée.")
      navigate(-1)
    }
  }

  const onValidate = async () => {
    let file: File | null = null
    setIsLoading(true)
    if (source.type === 'pdf') {
      const blob = await pdfRef?.saveAsBlob()
      if (blob) {
        file = new File([ blob ], media?.fileName || 'document.pdf')
        if (file) {
          await onSubmit(file)
        } else {
          navigate(-1)
        }
        setIsLoading(false)
      }
    } else if (imgRef) {
      const imageData = imgRef.getImageData()
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = imageData.width
      canvas.height = imageData.height
      if (!ctx) return
      ctx.putImageData(imageData, 0, 0)
      canvas.toBlob(async (blob) => {
        if (!blob) return
        file = new File([ blob ], media?.fileName || 'image.png')
        if (file) {
          await onSubmit(file)
        } else {
          navigate(-1)
        }
        setIsLoading(false)
      })
    }
  }

  return (
    <>
      <HeaderWithBackArrow
        onClick={() => navigate(-1)}
        title={`Editer un${source.type === 'pdf' ? ' document' : 'e image'}`}
      >
        <StyledButton
          variant="contained"
          disabled={isUpdatingDocument || isLoading}
          onClick={onValidate}
        >
          Valider
        </StyledButton>
      </HeaderWithBackArrow>
      <PageContainer id="annotate-page">
        {
          source.type === 'pdf' && source.url &&
            <MediaProcessorFallback mediaType="pdf">
              <PdfProcessor
                documentPath={source.url}
                setPdfRef={setPdfRef}
                pdfRef={pdfRef}
              />
            </MediaProcessorFallback>
        }
        {
          source.type === 'img' && source.url &&
            <MediaProcessorFallback mediaType="img">
              <ImageProcessor
                imgRef={imgRef}
                setImgRef={setImgRef}
              />
            </MediaProcessorFallback>
        }
      </PageContainer>
    </>
  )
}

export default MediaLibraryAnnotatePage
