/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useLocation } from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'

/* Styled components -------------------------------------------------------- */
interface MessageProps {
  error?: boolean;
}

const Message = styled.div<MessageProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => props.error ? props.theme.palette.error.main : props.theme.palette.primary.main};
  gap: 10px;
  height: 100vh;
`

/* Component declaration ---------------------------------------------------- */
interface MediaProcessorFallbackProps {
  children: React.ReactNode;
  mediaType: 'pdf' | 'word' | 'excel' | 'img';
}

const MediaProcessorFallback: React.FC<MediaProcessorFallbackProps> = ({ children, mediaType }) => {
  const { pathname } = useLocation()

  const mediaTypeText = useMemo(() => {
    switch (mediaType) {
      case 'excel':
        return 'du tableur'
      case 'pdf':
        return 'de la visionneuse de PDF'
      case 'img':
        return "de l'éditeur d'image"
      default:
        return "de l'éditeur de document"
    }
  }, [ mediaType ])

  return (
    <ErrorBoundary
      pathname={pathname}
      errorMessage={
        <Message error>
          {`Le chargement ${mediaTypeText} à échoué.\n\nVous pouvez tenter de réessayer, ou bien contacter les développeurs.`}
        </Message>
      }
    >
      <React.Suspense fallback={
        <Message>
          {`Chargement ${mediaTypeText}...`}
          <CircularProgress />
        </Message>
      }
      >
        {children}
      </React.Suspense>
    </ErrorBoundary>
  )
}

export default MediaProcessorFallback
