/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  CalendarViewTypes,
  defaultFullCalendarOptions,
  joursFeries,
} from 'helpers/FullCalendarOptions'
import DateUtils from 'helpers/DateUtils'
import { useIsDesktop } from 'helpers/hooks/useIsMedia'

/* Component imports -------------------------------------------------------- */
import FullCalendar from '@fullcalendar/react' /* Must be imported before other FullCalendar plugins */
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import PlanningCalendarEventRender from './PlanningCalendarEventRender/PlanningCalendarEventRender'

/* Type imports ------------------------------------------------------------- */
import type {
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
} from '@fullcalendar/core'
import {
  TypeRdvType,
  type Planning,
} from 'API/__generated__/Api'
import type {
  EventHandlerActionType,
  EventHandlerType,
} from 'types/EventHandler'

/* Styled components -------------------------------------------------------- */
const HeaderContent = styled.div`
  text-transform: uppercase;
  font-weight: normal;

  .day-number {
    font-size: 1.5rem;
    font-weight: bold;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    gap: 10px;

    .day-number {
      font-size: 1rem;
    }
  }
`

const Calendar = styled.div`
  height: 100%;
  color: ${(props) => props.theme.palette.secondary.main};

  .fc {
    background-color: white;
    height: 100%;
  }

  .fc .fc-bg-event {
    background-color: ${(props) => props.theme.colors.darkgrey}22 !important;
    color: ${(props) => props.theme.colors.darkgrey} !important;
    opacity: 1 !important;
  }

  .fc-daygrid table.fc-scrollgrid {
    border-collapse: separate;
  }

  a.fc-event {
    border: none;
  }

  a.fc-daygrid-event.fc-daygrid-dot-event.fc-event {
    margin: 0;
  }

  a.fc-daygrid-event.fc-daygrid-block-event.fc-event {
    border: none;
    margin: 2px 0px !important;
  }

  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
  }

  .fc-timegrid-event {
    margin-bottom: 0px;
  }

  .fc-timegrid-event .fc-event-main {
    padding: 0;
  }

  .fc .week-view {
    height: 55px;
  }

  .fc .day-view {
    height: 73px;
  }

  .fc .fc-list-table td {
    padding: 0px 0px 0px 10px;
  }

  .fc-list-event-time {
    align-content: center;
  }

  .fc-list-event-dot {
    display: none;
  }
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarProps {
  initialView?: string;
  initialDate: Date;
  calendarRef: React.RefObject<FullCalendar | null>;
  events: Planning[];
  setEventHandler?: (values: EventHandlerType) => void;
  onEventChange?: (type?: EventHandlerActionType | null, newEvent?: Planning) => void;
}

const PlanningCalendar: React.FC<PlanningCalendarProps> = ({
  initialView,
  initialDate,
  calendarRef,
  events,
  setEventHandler,
  onEventChange,
}) => {
  const isDesktop = useIsDesktop()

  const editEvent = (e: EventClickArg): void => {
    if (e?.event?.startEditable) {
      setEventHandler &&
      setEventHandler(
        {
          open: true,
          action: 'edit',
          event: events.find((event) => e.event.id === event.id),
          dates: null,
        },
      )
    } else {
      const className = (e.jsEvent.target as HTMLDivElement)?.className
      if (className && !isDesktop) {
        const elem = document.getElementsByClassName(className)?.[0]
        if (!elem) return
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        elem.blur()
      }
    }
  }

  const addEvent = (e: DateSelectArg): void => {
    setEventHandler &&
    setEventHandler(
      {
        open: true,
        action: 'add',
        event: undefined,
        dates: e,
      },
    )
  }

  const eventChange = (e: EventChangeArg): void => {
    const newEvent: Planning = {
      ...events.find((event) => event.id === e.event.id) as Planning,
      ...e.event,
      id: e.event.id || `${e.event.start?.toISOString()}-${e.event.end?.toISOString()}`,
      dateDebut: e.event.start?.toISOString() as string,
      dateFin: e.event.end?.toISOString() as string,
    }
    onEventChange && onEventChange('edit', newEvent)
  }

  const calendarFullYear = calendarRef.current?.getApi().getDate().getFullYear()
  const viewType = calendarRef.current?.getApi()?.view?.type as CalendarViewTypes

  const holidays = useMemo(() => joursFeries(calendarFullYear || new Date().getFullYear()), [ calendarFullYear ])
  const allEvents: EventInput[] = useMemo(() => [
    ...events.map((event: Planning): EventInput => ({
      start: event.dateDebut,
      end: event.dateFin,
      title: event.assure || event.typeRDV.libelle,
      editable: event.typeRDV.type === TypeRdvType.ActionGenerale ? event.idRecurrence ? false : true : false,
      id: event.id,
      backgroundColor: event.typeRDV?.color,
      allDay: event.journeeComplete || false,
      extendedProps: { recurring: event.idRecurrence ? true : false },
    })),
    ...Object.keys(holidays).map((holiday): EventInput => ({
      start: holidays[holiday].toISOString(),
      title: holiday,
      id: holiday,
      display: 'background',
      allDay: true,
    })),
  ], [ events, holidays ])

  return (
    <Calendar>
      <FullCalendar
        ref={calendarRef}
        {...defaultFullCalendarOptions}
        plugins={
          [
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            interactionPlugin,
          ]
        }
        initialView={initialView}
        initialDate={initialDate}
        slotLaneClassNames={viewType === CalendarViewTypes.Day ? 'day-view' : 'week-view'}
        dayHeaderContent={
          (event) => (
            <HeaderContent>
              {DateUtils.APIStrToLocalDateString(event.date.toISOString(), { weekday: 'long' })}
              {
                viewType !== CalendarViewTypes.Month &&
                  <div className="day-number">
                    {DateUtils.APIStrToLocalDateString(event.date.toISOString(), { day: '2-digit', 'month': '2-digit' })}
                  </div>
              }
            </HeaderContent>
          )
        }
        events={allEvents}
        eventContent={
          (eventInfo) => (
            <PlanningCalendarEventRender
              eventInfo={eventInfo}
              event={events.find((evt) => evt.id === eventInfo.event.id) as Planning}
              viewType={viewType || CalendarViewTypes.Month}
            />
          )
        }
        eventChange={eventChange}
        eventClick={editEvent}
        select={addEvent}
      />
    </Calendar>
  )
}

export default PlanningCalendar
