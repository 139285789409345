/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Dialog } from '@mui/material'
import DialogTitle from 'components/Dialog/DialogTitle'
import DocumentNameEditorForm from 'components/MediaRenderer/DocumentNameEditorForm'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DocumentNameEditorFormContainer = styled.div`
  padding: 15px 20px 5px;

  .MuiPaper-root  {
    border: none !important;
  }
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryRenameModalProps {
  handleClose: () => void;
  document: PieceJointe;
}

const MediaLibraryRenameModal: React.FC<MediaLibraryRenameModalProps> = ({
  handleClose,
  document,
}) => {

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Renommer / Supprimer
      </DialogTitle>
      <CloseButton handleClose={handleClose} />
      <DocumentNameEditorFormContainer>
        <DocumentNameEditorForm
          document={document}
          onEdition={() => null}
        />
      </DocumentNameEditorFormContainer>
    </Dialog>
  )
}

export default MediaLibraryRenameModal
